var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CycleTableSettingsStyled', [_c('div', [_vm._v(_vm._s(_vm.$tc('column', 2)) + ":")]), _c('SelectStyled', [_vm._l(_vm.cycleTableSettings, function (attribute, i) {
    return [_c('CheckBox', {
      key: i,
      attrs: {
        "label": attribute
      },
      on: {
        "checkedAttribute": _vm.checkedAttribute
      }
    })];
  })], 2), _vm.showDurationFormatter ? _c('DurationFormatMolecule', {
    attrs: {
      "widgetSettings": _vm.widgetSettings
    },
    on: {
      "changeWidgetSettings": function changeWidgetSettings($event) {
        return _vm.setDurationFormat($event);
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }