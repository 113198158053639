<template>
  <CycleTableSettingsStyled>
    <div>{{ $tc('column', 2) }}:</div>
    <SelectStyled>
      <template v-for="(attribute, i) in cycleTableSettings">
        <CheckBox :label="attribute" :key="i" @checkedAttribute="checkedAttribute" />
      </template>
    </SelectStyled>
    <DurationFormatMolecule
      :widgetSettings="widgetSettings"
      @changeWidgetSettings="setDurationFormat($event)"
      v-if="showDurationFormatter"
    />
  </CycleTableSettingsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import CheckBox from '@/components/Misc/CheckBox'
import ADDITIONAL_ATTRIBUTES from '@/components/Widgets/CycleTable/cycleTableAdditionalAttributes'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import DurationFormatMolecule from '@/components/Atomic/Molecules/DurationFormatMolecule'
import assetDimensionWithDurationFormat from '@/utils/widgets/assetDimensionWithDurationFormat'

const CycleTableSettingsStyled = styled('div')``
const SelectStyled = styled('div')`
  display: grid;
  margin-top: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-rows: minmax(10px, auto);
  grid-gap: 1em;
  font-size: 12px;
`

export default {
  props: {
    widgetSettings: {
      type: Object,
      required: true,
    },
    dimensionsSelected: {
      type: Array,
      required: false,
    },
  },
  components: {
    CycleTableSettingsStyled,
    CheckBox,
    SelectStyled,
    DurationFormatMolecule,
  },
  data() {
    return {
      additionalAttributes: ADDITIONAL_ATTRIBUTES,
      defaultSettings: {
        cycleIDColumnShown: true,
        endColumnShown: true,
        sourceAssetTalpaIDColumnShown: true,
        sourceGeofenceColumnShown: true,
        materialTypeColumnShown: true,
        startColumnShown: true,
        assetColumnShown: true,
        targetAssetTalpaIDColumnShown: true,
        targetGeofenceColumnShown: true,
        hasDecimalFormatEnabled: false,
      },
    }
  },
  computed: {
    widgetSettingsMapped() {
      if (isEmpty(this.widgetSettings)) {
        return this.defaultSettings
      } else {
        return this.widgetSettings
      }
    },
    cycleTableSettings() {
      return this.additionalAttributes.map(attribute => {
        return {
          id: attribute.id,
          name: this.$t(`cycleTable.${attribute.label}`),
          checked: this.widgetSettingsMapped[attribute.id],
          disabled: attribute.id === 'assetColumnShown',
        }
      })
    },
    selectedDimensionNames() {
      return this.dimensionsSelected.map(({ name }) => name)
    },
    selectedTimeBasedKPIs() {
      return assetDimensionWithDurationFormat.filter(dimension => this.selectedDimensionNames.some(selected => dimension === selected))
    },
    showDurationFormatter() {
      return this.selectedTimeBasedKPIs.length > 0
    },
  },
  methods: {
    checkedAttribute(attribute) {
      this.widgetSettingsMapped[attribute.id] = attribute.checked
      this.emitSettings()
    },
    setDurationFormat(selectedDurationFormat) {
      this.widgetSettingsMapped.hasDecimalFormatEnabled = selectedDurationFormat.hasDecimalFormatEnabled
      this.emitSettings()
    },
    emitSettings: debounce(function () {
      this.$emit('changeWidgetSettings', this.widgetSettingsMapped)
    }, 500),
  },
}
</script>
